import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import GridContent from '../components/UI/grid-content'
import { PrincipleIcon } from '../components/graphics/icons'
import AboveTheFold from '../components/UI/landing-page'
import Concertina from '../components/UI/concertina'

const Home = ({ data }) => {
  const { introduction, highlights, strategy } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const points = data.file.childMarkdownRemark.frontmatter.introduction.strategy
  // const articles = data.allMarkdownRemark.edges.filter(
  //   edge => edge.node.frontmatter.cms === 'news'
  // )

  // const release = data.allMarkdownRemark.edges.filter(
  //   edge => edge.node.frontmatter.cms === 'press'
  // )

  return (
    <Layout>
      <GatsbySeo
        title="Irbisio"
        description="Irbisio is a green energy fund dedicated to supporting the global transition to a sustainable future."
      />
      <AboveTheFold />
      <GridContent
        id="introduction"
        invert="true"
        layout="--center-4 margin-none-mobile"
        mode={introduction.mode}
        background={introduction.image}
      >
        <h4>{introduction.section}</h4>
        <h1>{introduction.title}</h1>
        <p className="markdown">{introduction.body}</p>
      </GridContent>
      <GridContent
        id="mission"
        layout="--center-4"
        mode={strategy.page_1.mode}
        background={strategy.page_1.image}
      >
        <h4>{strategy.page_1.section}</h4>
        <h1>{strategy.page_1.title}</h1>

        <h2 className="markdown">{strategy.page_1.sub_heading}</h2>
        <p
          className="markdown"
          dangerouslySetInnerHTML={{ __html: strategy.page_1.body }}
        />
        {points.map(node => {
          return (
            <div key={node.title}>
              <span className="row">
                <PrincipleIcon type={node.icon} />
                <span>
                  <h6>{node.title}</h6>
                </span>
              </span>
              <div
                dangerouslySetInnerHTML={{ __html: node.body }}
                className="markdown"
              />
            </div>
          )
        })}
        <Link className="mock-button leopard" to="/strategy">
          Learn More
        </Link>
      </GridContent>
      <GridContent id="press" layout="--center-4" mode="dark-mode">
        <h4>Press Release</h4>
        <h2>{data.allMarkdownRemark.edges[0].node.frontmatter.title}</h2>
        <Concertina article={data.allMarkdownRemark.edges[0].node.html} />
      </GridContent>
      <GridContent
        id="highlights"
        layout="--center-4"
        mode="light-mode"
        background={strategy.page_2.image}
      >
        <h4>highlights</h4>
        <div
          className="strategy-priorities"
          style={{ gridTemplate: 'auto / auto' }}
        >
          {highlights.points.map((point, i) => {
            return (
              <p
                key={i}
                className="markdown"
                dangerouslySetInnerHTML={{
                  __html: point.body,
                }}
              />
            )
          })}
        </div>
        <h4>Featured Initiatives</h4>
        <p
          className="markdown"
          dangerouslySetInnerHTML={{ __html: highlights.featured.body }}
        />
        <Link
          className="mock-button leopard"
          to={highlights.featured.button.link}
        >
          {highlights.featured.button.label}
        </Link>
      </GridContent>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "home" } }) {
      edges {
        node {
          id
          childMarkdownRemark {
            fields {
              slug
            }
            frontmatter {
              about {
                body
                button {
                  label
                  link
                }
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                mode
                section
                title
              }
              introduction {
                body
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                mode
                section
                title
              }
              highlights {
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                mode
                points {
                  body
                }
                featured {
                  button {
                    label
                    link
                  }
                  body
                }
              }
              leopard {
                body
                button {
                  label
                  link
                }
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                image_mobile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                mode
                section
                title
              }
              strategy {
                page_1 {
                  section
                  body
                  mode
                  title
                  sub_heading
                  image {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 800
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
                page_2 {
                  section
                  body
                  mode
                  statement
                  background
                  priorities {
                    title {
                      title
                      body
                      icon
                    }
                  }
                  button {
                    label
                    link
                  }
                  image {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 800
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    file(name: { eq: "strategy" }) {
      childMarkdownRemark {
        frontmatter {
          introduction {
            strategy {
              body
              title
              icon
            }
          }
        }
      }
    }

    allMarkdownRemark(filter: { frontmatter: { cms: { eq: "press" } } }) {
      edges {
        node {
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`
