import React, { useRef, useState, useEffect } from 'react'
import { animated, useSpring, config } from 'react-spring'
import useMediaQuery from '../Hooks/MatchMedia'

const Concertina = ({ article }) => {
  const [open, isOpen] = useState(false)
  const accord = useRef(null)
  //const [height, setHeight] = useState('0px')
  const mobile = useMediaQuery('(max-width: 768px)')

  const [copy, setCopy] = useState(mobile ? 0 : 229)

  const styles = useSpring({
    config: config.gentle,
  })

  useEffect(() => {
    const closed = mobile ? 130 : 229
    if (open) {
      setCopy(10000)
      //setHeight(accord.current.scrollHeight + 'px')
    } else setCopy(closed) //setHeight('0px')
  }, [open, mobile])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <animated.div
        className="accordian markdown"
        style={styles}
        ref={accord}
        dangerouslySetInnerHTML={{
          __html: open
            ? article.substring(0, copy)
            : article.substring(0, copy) + '...',
        }}
      />
      <button
        className="mock-button align-right leopard"
        style={{
          justifySelf: 'end',
          width: 'fit-content',
          marginLeft: 'auto',
          minHeight: '36px',
        }}
        onClick={() => isOpen(!open)}
      >
        {open ? 'Read Less' : 'Read More'}
      </button>
    </div>
  )
}

export default Concertina
