import React, { useRef, useEffect } from 'react'
import useMediaQuery from '../Hooks/MatchMedia'
import { useInView } from 'react-spring'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { AnimatedLogo } from '../graphics/logo'

const AboveTheFold = () => {
  const [containerRef, isInView] = useInView({})
  const mobile = useMediaQuery('(max-width: 768px)')
  const videoRef = useRef(null)

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "header" } }) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                landing {
                  blend
                  slogan
                  delay
                  logo_color
                  video_mp4 {
                    publicURL
                  }
                  video_webm {
                    publicURL
                  }
                  image {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 800
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { image, video_webm, video_mp4, blend, logo_color, slogan, delay } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter.landing

  // Set the video height to 16:9
  useEffect(() => {
    if (!mobile)
      containerRef.current.style.height = window.innerWidth / 1.77777 + 'px'
  }, [mobile])

  useEffect(() => {
    if (videoRef.current)
      isInView ? videoRef.current.play() : videoRef.current.pause()
  }, [isInView])

  useEffect(() => {
    if (document.readyState !== 'loading' && videoRef.current) {
      videoRef.current.classList.add('reveal')
    }
  }, [videoRef.current])

  return (
    <div className="video-container atf" id="atf" ref={containerRef}>
      <GatsbyImage
        image={getImage(image)}
        className="video-still-img"
        alt="holding image"
      />
      {!mobile && (
        <video
          ref={videoRef}
          autoPlay
          loop
          preload="none"
          muted
          playsInline
          style={{
            width: '100vw',
            height: 'auto',
            position: 'relative',
            opacity: 0,
            transition: 'opacity 2s',
          }}
        >
          <source src={video_mp4.publicURL} type="video/mp4" />
          <source src={video_webm.publicURL} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      )}
      <div className="logo-center">
        <AnimatedLogo
          color={logo_color}
          blend={blend}
          slogan={slogan}
          delay={delay}
        />
      </div>
    </div>
  )
}

export default AboveTheFold
